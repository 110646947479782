
import { defineComponent, onMounted, ref } from "vue";
import { config } from "@/core/helpers/config";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";

export default defineComponent({
	name: "builder",
	components: {
		CodeHighlighter,
	},
	setup() {
		const tabIndex = ref(0);

		onMounted(() => {
			// set the tab from previous
			tabIndex.value = parseInt(
				localStorage.getItem("builderTab") || "0"
			);

		});

		/**
		 * Reset config
		 * @param event
		 */
		const reset = (event) => {
			event.preventDefault();
			// remove existing saved config
			localStorage.removeItem("config");
			window.location.reload();
		};

		/**
		 * Set active tab when the tab get clicked
		 * @param event
		 */
		const setActiveTab = (event) => {
			tabIndex.value = parseInt(
				event.target.getAttribute("data-tab-index")
			);
			// keep active tab
			localStorage.setItem("builderTab", tabIndex.value.toString());
		};

		/**
		 * Submit form
		 * @param event
		 */
		const submit = (event) => {
			event.preventDefault();
			// save new config to localStorage
			localStorage.setItem("config", JSON.stringify(config.value));
			window.location.reload();
		};

		return {
			tabIndex,
			config,
			reset,
			setActiveTab,
			submit,
		};
	},
});
